<template>
	<main id="container" class="page-container page-category bg-wave2">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="동행제품 카테고리" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div class="category-search">
					<h2 class="category-search-title">제품검색</h2>
					<div class="category-search-area">
						<input type="text" class="category-search-input" v-model="searchName" placeholder="제품명을 입력하세요." @keyup.enter="searchManprdList('')" />
						<button class="category-search-button" @click="searchManprdList('')">
							<i class="icon">
								<img src="@/assets/img/category/search_icon.svg" alt="검색" />
							</i>
						</button>
					</div>
				</div>
				<div class="category-items">
					<ul class="category-items-list">
						<li class="category-items-list-li" v-for="item in ctgrItems" :key="item.dcd">
							<button type="button" :class="item.dcd == ctgrDcd ? 'category-item is-active' : 'category-item'" @click="searchManprdList(item.dcd)">
								<!-- <div class="category-item is-active"> -->
								<div class="category-item-img">
									<i class="icon">
										<img :src="require(`@/assets/img/category/${item.img}`)" alt="" />
									</i>
								</div>
								<span class="item-title">{{ item.dcdVal }}</span>
							</button>
						</li>
					</ul>
				</div>
				<div class="products-list">
					<products-item v-for="(item, idx) in manprdList" :key="item.manprdId" :id="item.id" :item="item" type="" parent-like-evnt-nm="likeClicked" @likeClicked="handleClickLike" parent-click-evnt-nm="modalClicked" @modalClicked="handleClickModal" />
				</div>
			</div>
		</div>
		<products-modal :visible="visibleModal" @closeModal="visibleModal = false" :item="modalItem" parent-click-evnt-nm="clickEvnt" @clickEvnt="handleClickModalEvnt" />
		<div class="pagination-wrap">
			<portal-pagination page-name="Category" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @getManprdList="getManprdList"></portal-pagination>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_COMMON_CODE_LIST, ACT_GET_CAMPN_MANPRD_LIST, ACT_UPDATE_CAMPN_MANPRD_FAVR, ACT_UPDATE_CAMPN_MANPRD_STAR } from '@/store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM, MUT_SHOW_ALERT } from '@/store/_mut_consts';

import { getItems, lengthCheck, getCheckItems, setPaging, tryResCallback, queryToValue, isSuccess, timestampToDateFormat } from '@/assets/js/utils';

import PortalPagination from '@/components/PortalPaginationRoute';
import PageHeader from '@/components/content/PageHeader';
import ProductsModal from '@/components/content/ProductsModal';
import ProductsItem from '@/components/content/ProductsItem';
export default {
	name: 'Category',
	components: { PortalPagination, PageHeader, ProductsItem, ProductsModal },
	data: () => ({
		pagingMethodName: 'getManprdList',
		pageNo: 1,
		pageSize: 10,
		pageListSize: 10,
		totalCount: 0,
		searchName: '',
		visibleModal: false,
		ctgrDcd: '',
		ctgrItems: [],
		modalId: 0,
		modalType: '',
		manprdList: [],
		manprdAllList: [],
		manprdFilterList: [],
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		...mapGetters('auth', ['session', 'isAuth']),
		campnId() {
			return this.campn.campnId;
		},
		modalItem() {
			if (this.modalId > 0) return this.manprdAllList.find((i) => i.id == this.modalId);

			return null;
		},
		queries() {
			const result = {};
			result.searchName = this.searchName;
			result.ctgrDcd = this.ctgrDcd;
			return result;
		},
	},
	watch: {},
	async created() {
		if(this.isMobile) this.pageListSize = 5;
		this.getCtgrList();
		this.getManprdAllList();
	},
	mounted() {},
	methods: {
		async getCtgrList() {
			this.$store
				.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, { dcd: '1501', masterYn: 'N' })
				.then((res) => {
					if (lengthCheck(res)) {
						const items = getItems(res).map((i) => {
							let item = {};
							switch (i.dcd) {
								case '1501001':
									item = { ...i, img: 'list_item02.png', title: '뷰티' };
									break;
								case '1501002':
									item = { ...i, img: 'list_item03.png', title: '생활' };
									break;
								case '1501003':
									item = { ...i, img: 'list_item04.png', title: '식품' };
									break;
								case '1501004':
									item = { ...i, img: 'list_item05.png', title: '전자기기' };
									break;
								case '1501005':
									item = { ...i, img: 'list_item06.png', title: '가구·조명' };
									break;
								case '1501006':
									item = { ...i, img: 'list_item07.png', title: '주방' };
									break;
								case '1501007':
									item = { ...i, img: 'list_item08.png', title: '패션·잡화' };
									break;
								default:
									break;
							}

							return item;
						});

						this.ctgrItems = this.ctgrItems.concat(
							{
								dcd: '',
								dcdVal: '전체',
								img: 'list_item01.png',
								title: '전체',
							},
							items
						);
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getManprdAllList() {
			this.pageNo = 1;
			this.manprdList = [];

			// api 호출
			this.$store
				.dispatch(`manprd/${ACT_GET_CAMPN_MANPRD_LIST}`, {
					campnId: this.campnId,
					manprdNm: '',
					ctgrDcd: '',
					sortCd: '',
					pageNo: 1,
					pageSize: 1000,
				})
				.then((res) => {
					tryResCallback(this.tryCount, res, this.getManprdAllList, this.setManprdAllList);
				})
				.catch((e) => {
					console.error(e);
				});
		}, 
		setManprdAllList(res) {
			if (lengthCheck(res)) {
				this.manprdFilterList = this.manprdAllList = getCheckItems(res).map((i) => {
					return {
						id: i.manprdId,
						image: i.manprdImgFileUrl,
						company: i.brndNm,
						title: i.manprdNm,
						price: i.manprdPrc,
						rate: i.socreAvg,
						rateCount: i.scoreCnt,
						like: i.favrYn == 'Y',
						likeCount: i.favrCnt,
						myRate: i.myScore,
						desc: i.manprdDesc,
						siteUrl: i.ntslSiteUrl,
						isEng : false,
						useCampnDurYn: i.useCampnDurYn == 'Y',
						ntslStrtDt: timestampToDateFormat(i.ntslStrtDt, 'yyyy-MM-dd'),
						ntslEndDt: timestampToDateFormat(i.ntslEndDt, 'yyyy-MM-dd'),
						ctgrDcd: i.ctgrDcd,
					};
				});
				this.totalCount = res.paging.totalCount;
				this.searchManprdList(this.ctgrDcd);
			}
			
			//setPaging(this, res);
		},
		getManprdList(pageNo) {
			this.pageNo = pageNo;
			this.manprdList = [];
			this.manprdList = this.manprdFilterList.filter((m, idx) => idx >= (this.pageNo - 1) * this.pageSize && idx < this.pageNo * this.pageSize);
		},
		searchManprdList(dcd) {
			this.ctgrDcd = dcd;
			let searchManprdList = [];

			if(this.searchName) {
				searchManprdList = this.manprdAllList.filter((m) => m.title.indexOf(this.searchName) > -1);
			} 
			else {
				searchManprdList = this.manprdAllList ;
			}

			if(this.ctgrDcd) this.manprdFilterList = searchManprdList.filter((m) => m.ctgrDcd == this.ctgrDcd);
			else this.manprdFilterList = searchManprdList;

			this.totalCount = this.manprdFilterList.length;
			this.getManprdList(1);
		},
		// 찜하기
		handleClickLike(idx, type) {
			this.getManprdAllList();
			// const item = this.manprdList.find(i => i.id == idx);
			// this.setFavr(item);
		},
		handleClickModal(idx, type) {
			this.modalId = idx;
			this.visibleModal = true;
		},
		handleClickModalEvnt(type, score) {
			this.getManprdAllList();
			// if(type == "F") this.setFavr(this.modalItem);
			// else this.setStar({ ...this.modalItem, myRate: score});
		},
	},
};
</script>
